import React from 'react'


const NotFoundPage = () => {
  return (
    <div className='flex flex-col justify-center items-center min-h-screen'>
      <h1 className='text-2xl mb-4 font-bold'>404 - Page Not Found</h1>
      <p className='mb-4'>The page you are looking for does not exits.</p>
      
    </div>
  )
}

export default NotFoundPage;
