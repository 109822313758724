import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./ShortUrlPage.css";

const ShortUrlPage = () => {
  const { shortUrl } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const logGeneratedRef = useRef(false); 

  const baseUrl = process.env.REACT_APP_URL;
  const shortUrlEndPoint = `${baseUrl}/v1/public/short-url/${shortUrl}`;
  const browserEndPoint = `${baseUrl}/v1/public/short-url/logs`;

  const browserDetails = {
    userAgent: navigator.userAgent,
    languages: navigator.languages,
    platform: navigator.platform,
    vendor: navigator.vendor,
    userAgentData: navigator.userAgentData,
    connection: navigator.connection,
    screenSize: {
      width: `${window.innerWidth} pixels`,
      height: `${window.innerHeight} pixels`,
    },
  };

  const fetchLongUrlAndRedirect = async () => {
    const longUrl = await fetchLongUrl();
    if (longUrl) {
      setLoading(false);

      const generateLogsAndRedirect = async (locationDetails) => {
        if (!logGeneratedRef.current) {
          logGeneratedRef.current = true;
          const payload = {
            shortUrl: shortUrl,
            browserDetails: browserDetails,
            locationDetails: locationDetails,
          };
          await generateLogs(payload);
          window.location.replace(longUrl);
        }
      };

      getLocation()
        .then((locationDetails) => {
          generateLogsAndRedirect(locationDetails);
        })
        .catch((error) => {
          console.error('Error fetching location:', error.message);
          generateLogsAndRedirect(null);
        });

      setTimeout(() => {
        if (!logGeneratedRef.current) {
          generateLogsAndRedirect(null);
        }
      }, 5000);
    }
  };

  const fetchLongUrl = async () => {
    try {
      const response = await fetch(shortUrlEndPoint);
      if (response.status === 404) {
        setLoading(false);
        setTimeout(() => navigate('/404'), 1000);
        return null;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.longUrl;
    } catch (error) {
      console.error('Error fetching long URL:', error.message);
      setLoading(false);
      setTimeout(() => navigate('/404'), 1000);
      return null;
    }
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const locationDetails = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
            };
            resolve(locationDetails);
          },
          (error) => reject(error)
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };

  const generateLogs = async (payload) => {
    try {
      const response = await fetch(browserEndPoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Logs API Success:', data);
    } catch (error) {
      console.error('Error generating logs:', error.message);
    }
  };

  useEffect(() => {
    if (shortUrl) {
      fetchLongUrlAndRedirect();
    } else {
      console.error('No short URL found in the path.');
      setLoading(false);
      setTimeout(() => navigate('/404'), 1000);
    }
  }, [shortUrl, navigate]);

  return (
    <div>
      {loading && <div id="loader" className="loader"></div>}
    </div>
  );
};

export default ShortUrlPage;
