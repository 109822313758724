// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/ShortUrlPage.css */
.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #444444;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .hidden {
    display: none;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ShortUrlPage.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,yBAAyB;IACzB,kBAAkB;IAClB,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;EACd;;EAEA;IACE,aAAa;EACf;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["/* src/ShortUrlPage.css */\n.loader {\n    border: 8px solid #f3f3f3;\n    border-radius: 50%;\n    border-top: 8px solid #444444;\n    width: 40px;\n    height: 40px;\n    animation: spin 2s linear infinite;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    margin: auto;\n  }\n  \n  .hidden {\n    display: none;\n  }\n  \n  @keyframes spin {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
