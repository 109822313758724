import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ShortUrlPage from './components/ShortUrlPage';
import NotFoundPage from './components/NotFoundPage';
import './App.css';  // Import global styles

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:shortUrl" element={<ShortUrlPage />} />
        <Route path="/" element={<div></div>} />
        <Route path="*" element={<NotFoundPage />} /> {/* 404 route */}
        <Route path="/404" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;