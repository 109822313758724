// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */

/* Loader styles */
#loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #444444;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: fixed; /* Use fixed positioning for loader */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.hidden {
  display: none;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB,kBAAkB;AAClB;EACE,yBAAyB;EACzB,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,eAAe,EAAE,qCAAqC;EACtD,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* src/App.css */\n\n/* Loader styles */\n#loader {\n  border: 8px solid #f3f3f3;\n  border-radius: 50%;\n  border-top: 8px solid #444444;\n  width: 40px;\n  height: 40px;\n  animation: spin 2s linear infinite;\n  position: fixed; /* Use fixed positioning for loader */\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: auto;\n}\n\n.hidden {\n  display: none;\n}\n\n@keyframes spin {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
